import axios from '@/libs/axios'
import i18n from "@/libs/i18n";

const adSenseAdActions = {
    fetchAdSenseAds({commit, dispatch}, searchParams) {
        return axios.post('/adsense-ads/search', {params: searchParams})
    },
    fetchAdSenseAd({commit, dispatch}, id) {
        return axios.get(`/adsense-ads/${id}`)
    },
    storeAdSenseAd({commit, dispatch}, data) {
        return axios.post('/adsense-ads', data)
    },
    updateAdSenseAd({commit, dispatch}, data) {
        return axios.put(`/adsense-ads/${data.id}`, data)
    },
    deleteAdSenseAd({commit, dispatch}, id) {
        return axios.delete(`/adsense-ads/${id}`)
    },
    fetchAdSenseAdStatuses({}) {
        return axios.get('/adsense-ads/statuses')
    },
    fetchAdSenseAdPositions({}) {
        return axios.get('/adsense-ads/positions')
    },
}

export default adSenseAdActions
